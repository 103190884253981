<template>
	<div class="page container">

		<v2-back-btn :route="`/teaching/students`" />
		<div class="page__info" v-if="getNameString">
			<div class="page__info--title">
				{{ getNameString }}
			</div>
		</div>

		<list-view
			v-if="getStudent"
			:list="getList"
		/>

	</div>
</template>

<script>

	import V2BackBtn from '@/components/v2/ui/V2BackBtn';
	import ListView      from '@/components/ui/views/listView/ListView';
	import api           from '@/services/api';
	import dayjs         from 'dayjs';
	import relativeTime  from 'dayjs/plugin/relativeTime';

	dayjs.extend(relativeTime);

	export default {
		layout: 'v2default',
		metaInfo: {
			title: 'Student information'
		},
		components: {
			V2BackBtn,
			ListView
		},
		data: () => ({
			student: undefined
		}),
		computed: {
			getStudentId () {
				return this.$route.params.studentId;
			},
			getStudent () {
				if (!this.student ||
						!this.student.id) {
					return false;
				}
				return this.student;
			},
			getNameString (student) {
				if (!this.getStudent) {
					return false;
				}
				return `${this.getStudent.firstName} ${this.getStudent.lastName}`;
			},
			getList () {
				if (!this.getStudent) {
					return false;
				}
				return [
					{
						text: `Is enrolled in ${this.student.numCourses} of my courses`
					},
					{
						text: `Preferred bidding system: ${this.student.biddingSystem}`
					},
					{
						text: `Experience level: ${this.student.experienceLevel}`
					}
				];
			},
			getBreadcrumbs () {
				return [
					{
						path: '/teaching',
						text: 'Teaching'
					},
					{
						path: '/teaching/students',
						text: 'Students'
					},
					{
						path: `/teaching/students/${this.getStudentId}`,
						text: this.getNameString
					}
				];
			}
		},
		created: function () {
			this.setStudent();
		},
		methods: {
			async setStudent () {
				const student = await api.teaching.getStudentById({
					fields: [
						'firstName',
						'lastName',
						'numCourses',
						'biddingSystem',
						'experienceLevel',
						'id'
					],
					studentId: this.getStudentId
				});
				if (!student) {
					this.$store.commit('ui/showError');
					return false;
				}
				this.student = student;
				this.$store.commit('ui/setBreadcrumbs', {
					breadcrumbs: this.getBreadcrumbs
				});
				this.$store.commit('ui/setLoadingIsHidden');
			}
		}
	};
</script>
